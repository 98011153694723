export const PetCaregiverFilter = {
  ServiceTypeDropdown: 'service-type-dropdown',
  CitySearchLabel: 'city-search-label',
  CitySearchDropdown: 'city-search-dropdown',
  RatesSliderThumb: 'rates-slider-thumb',
  DistanceSliderThumb: 'distance-slider-thumb',
  HomeType: 'home-type',
  ServiceFilterCheckbox: 'service-filter-checkbox',
  SpecialtiesDropdown: 'specialties-dropdown',
};
