export const AddPetInfo = {
  NameField: 'name-field',
  BreedField: 'breed-field',
  MixedBreed: 'mixed-breed',
  AgeSlider: 'age-slider',
  Gender: {
    Male: 'male',
    Female: 'female',
  },
  SpayedNeutered: {
    Spayed: 'spayed',
    Neutered: 'neutered',
  },
  Size: {
    Small: 'small-weight-card',
    Medium: 'medium-weight-card',
    Large: 'large-weight-card',
    XL: 'xl-weight-card',
  },
  PhotoUploader: 'photo-uploader',
  SubmitBtn: 'booking-submit-button',
  ErrorText: 'error-text',
  BackBtn: 'back-button',
} as const;

export const BookingFlowWrapper = {
  CaregiverChip: 'caregiver-chip',
};

export const AddPaymentInfo = {
  AddCardButton: 'add-card',
};
