import { executionEnvironment } from '../../wag-react/common/xenv';

type SetCookie = (name: string, value: string, minutes?: number, domain?: string) => void;
export const setCookie: SetCookie = (name, value, minutes, domain) => {
  if (executionEnvironment.isSSR) {
    return;
  }
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes);
    expires = `; expires=${date.toUTCString()}`;
  }

  /*
    To make the cookie storage work locally
    the domain property must be left out of the
    document.cookie expression, NEXT_PUBLIC_WAG_WEBAPP_URL
    must also be set to http://localhost:PORT in .env.local/.env.development
    for this to work. The wagweb staging domain does
    https://stackoverflow.com/questions/1134290/cookies-on-localhost-with-explicit-domain
  */
  if (domain.indexOf('localhost') > -1) {
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
    return;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/; domain=${domain}`;
};
