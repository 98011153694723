import React from 'react';
import { Container, ContainerProps } from '../Container';
import { HowItWorksBlock, Props as HowItWorksBlockProps, HowItWorksBlockItemProp } from './components/HowItWorksBlock';
export type Props = Omit<ContainerProps, 'children'> & Partial<Pick<ContainerProps, 'children'>> & Pick<HowItWorksBlockProps, 'headingText' | 'headingTextProps' | 'items'>;
export type HowItWorksSectionProps = Props;
export type HowItWorksSectionItemProp = HowItWorksBlockItemProp;
export const HowItWorksSection = ({
  headingText,
  headingTextProps,
  items,
  ...rest
}: Props) => <Container
// ! TODO: on walking and overnight city pages `pb` is `40px`
pb={['60px', '40px']} pt={['48px', '40px']} {...rest} data-sentry-element="Container" data-sentry-component="HowItWorksSection" data-sentry-source-file="index.tsx">
    <HowItWorksBlock headingText={headingText} headingTextProps={headingTextProps} items={items} data-sentry-element="HowItWorksBlock" data-sentry-source-file="index.tsx" />
  </Container>;