import React, { ReactNode } from 'react';
import { Image, ImageProps } from '../../wag-react/next-components/image';
import { Flex, FlexProps } from '../Flex';
import { Typography } from '../Typography';
export type Props = {
  descriptionText?: string;
  descriptionBlock?: ReactNode;
  illustrationSrc: string;
  illustrationAlt?: string;
  illustrationProps?: ImageProps;
  titleText?: string;
} & FlexProps;
export const IllustrationAndText = ({
  descriptionText,
  descriptionBlock,
  illustrationSrc,
  illustrationAlt = 'illustration',
  illustrationProps,
  titleText,
  ...rest
}: Props) => <Flex alignItems="center" flexDirection="column" {...rest} data-sentry-element="Flex" data-sentry-component="IllustrationAndText" data-sentry-source-file="index.tsx">
    <Image height={['110px', '130px']} src={illustrationSrc} width={['110px', '130px']} alt={illustrationAlt} {...illustrationProps} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    <Flex alignItems="center" flexDirection="column" mt="24px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
      {titleText && <Typography fontWeight="bold" m={0} textAlign="center" variant="body1">
          {titleText}
        </Typography>}
      {descriptionBlock || <Typography mb={0} mt="16px" textAlign="center" variant="body1">
          {descriptionText}
        </Typography>}
    </Flex>
  </Flex>;