import React, { ReactNode } from 'react';
import negate from 'ramda/src/negate';
import { SITE_HEADER_DESKTOP_HEIGHT, SITE_HEADER_MOBILE_HEIGHT, SITE_HEADER_TABLET_HEIGHT } from '../../wag-react/components/site-navigation/constants';
import { Box, BoxProps } from '../../wag-react/next-components/box';
type Props = BoxProps & Required<Pick<JSX.IntrinsicElements['div'], 'id'>> & {
  children: ReactNode;
  offset?: number;
};
export const ScrollableElementContainer = ({
  children,
  offset = 25,
  ...rest
}: Props) => <Box mt={[negate(SITE_HEADER_MOBILE_HEIGHT + offset), negate(SITE_HEADER_TABLET_HEIGHT + offset), negate(SITE_HEADER_DESKTOP_HEIGHT + offset)]} pt={[SITE_HEADER_MOBILE_HEIGHT + offset, SITE_HEADER_TABLET_HEIGHT + offset, SITE_HEADER_DESKTOP_HEIGHT + offset]} {...rest} data-sentry-element="Box" data-sentry-component="ScrollableElementContainer" data-sentry-source-file="index.tsx">
    {children}
  </Box>;
export type ScrollableElementContainerProps = Props;