import {
  post,
  usePost,
  ExecutorFunction,
  Config,
} from './usePost';

import {
  WAG_API_URL,
  WagApiEndpoint,
} from '../../constants/endpoints';
import { WagApiError } from '../../utils/extractWagApiError';
import { Attribution } from '../../types/api';

export type PayloadValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
  referrerCode?: string;
  source?: string; // defaults to website on the backend
  attribution?: Attribution;
};

export type PostDogWalkerApplicationSuccessResponse = {
  authenticationMethod: string;
  identifier: string;
  session: {
    expiresAt: string;
    token: string;
    type: string;
  };
  type: string;
};

export type PostDogWalkerApplicationResponse
= PostDogWalkerApplicationSuccessResponse;

export type PostDogWalkerApplicationErrorResponse = WagApiError;

export const postDogWalkerApplication: ExecutorFunction<
  PayloadValues,
  PostDogWalkerApplicationResponse
> = (
  {
    payload: {
      email,
      firstName,
      middleName,
      lastName,
      phone,
      referrerCode,
      attribution,
      source,
    },
  }: Config<PayloadValues>,
) => post({
  baseUrl: WAG_API_URL,
  payload: {
    email,
    firstName,
    middleName,
    lastName,
    phone,
    referrerCode,
    attribution,
    source,
  },
  endpoint: WagApiEndpoint.PostWalkerSignup,
});

export const usePostDogWalkerApplication = () => {
  const result = usePost({
    executor: postDogWalkerApplication,
  });

  return result;
};
