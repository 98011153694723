'use client';

import {
  useState,
} from 'react';

import { getCookie } from '../../utils/getCookie';
import { CookieKey } from '../../constants/cookie-key';
import {
  HttpHeaderKey,
} from '../../constants';
import { HttpClientResponse } from '../../wag-react/common/api/types/http-client-response';
import { httpPost } from '../../wag-react/common/api/api';
import { Headers } from '../../utils/api/getRedirectLocation';

export type ExecutorFunction<PayloadValues, Response = Record<string, unknown>> =(
  config: Partial<Config<PayloadValues>>
) => Promise<HttpClientResponse<Response>>;

export type Config<PayloadValues = Record<string, unknown>, Response = Record<string, unknown>> = {
  endpoint: string;
  baseUrl: string;
  payload: PayloadValues;
  headers: Headers;
  // Define a custom post executor for customization
  executor?: ExecutorFunction<PayloadValues, Response>;
};

export const post = <PayloadValues, Response>(config: Partial<Config<PayloadValues, Response>>) => {
  const {
    endpoint,
    payload,
    baseUrl,
    headers,
  } = config;
  return (
    httpPost(
      endpoint,
      payload,
      {
        baseURL: baseUrl,
        headers,
      },
    ) as Promise<HttpClientResponse<Response>>
  );
};

// Reusable Http post hook that accepts a config object
export const usePost = <PayloadValues>(config: Partial<Config<PayloadValues>> = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(null);

  const defaultHeaders = {
    [HttpHeaderKey.XCsrfToken]: getCookie(CookieKey.CsrfToken),
  };

  // This allows the consumer to pass the payload only
  const execute = async (extraConfig: Partial<Config<PayloadValues>>) => {
    setIsLoading(true);
    try {
      const mergedHeaders = {
        ...defaultHeaders,
        ...config.headers,
        ...extraConfig.headers,
      };
      const executor = config.executor || post;
      const response = await executor({
        ...config,
        ...extraConfig,
        headers: {
          ...mergedHeaders,
        },
      });
      setIsLoading(false);
      setData(response);
      setIsSuccess(true);
      return response;
    } catch (_error) {
      setError(_error);
      setIsLoading(false);
      throw _error;
    }
  };
  return {
    execute,
    isLoading,
    isSuccess,
    error,
    data,
  };
};
