import { useSegment } from './useSegment';

import {
  SegmentIdentifySchema,
  segmentIdentifySchema,
} from '../utils/validation-schemas/segment/identify';

export const useSegmentIdentify = () => {
  const segment = useSegment();

  const execute = async ({
    data,
    options = {},
  }: {
    data: SegmentIdentifySchema;
    options?: SegmentAnalytics.SegmentOpts;
  }) => {
    const {
      userId,
      traits,
    } = await segmentIdentifySchema.validate(data);
    segment.identify(userId, traits, options);
  };

  return {
    execute,
  };
};
