import { executionEnvironment } from '../../wag-react/common/xenv';

type GetCookieOptions = {
  // Support for SSR
  rawCookie?: string;
};
type GetCookie = (name?: string, options?: GetCookieOptions) => string;

const transformCookieToMap = (cookie: string) => (
  cookie.split(';')
    .reduce((accumulator, currentValue) => {
      const [key, value] = currentValue.split('=');
      accumulator[key.trim()] = value;
      return accumulator;
    }, {})
);

export const getCookie: GetCookie = (name, options = {}) => {
  if (executionEnvironment.isSSR) {
    const { rawCookie } = options;
    if (!rawCookie) {
      return null;
    }
    const cookieMap = transformCookieToMap(rawCookie);
    return cookieMap[name] || cookieMap;
  }

  const { cookie } = document;

  const cookieMap = transformCookieToMap(cookie);
  return cookieMap[name] || cookieMap;
};
