import * as Yup from 'yup';
import { UnpackRecord } from '../../../types';

// https://segment.com/docs/connections/spec/identify/

const addressSchema = Yup.object().shape({
  city: Yup.string().notRequired(),
  country: Yup.string().notRequired(),
  postalCode: Yup.string().notRequired(),
  state: Yup.string().notRequired(),
  street: Yup.string().notRequired(),
}).notRequired().default(undefined);

const companySchema = Yup.object({
  name: Yup.string(),
  id: Yup.number(),
  industry: Yup.string(),
  employeeCount: Yup.number(),
  plan: Yup.string(),
}).notRequired().default(undefined);

export const segmentIdentifySchema = Yup.object({
  userId: Yup.string().required(),
  traits: Yup.object({
    address: addressSchema,
    age: Yup.number().nullable().notRequired(),
    avatar: Yup.string().notRequired(),
    birthday: Yup.date().notRequired(),
    company: companySchema,
    createdAt: Yup.date().notRequired(),
    description: Yup.string().notRequired(),
    email: Yup.string().email().notRequired(),
    firstName: Yup.string().notRequired(),
    gender: Yup.string().notRequired(),
    id: Yup.string().notRequired(),
    lastName: Yup.string().notRequired(),
    name: Yup.string().notRequired(),
    phone: Yup.string().notRequired(),
    title: Yup.string().notRequired(),
    username: Yup.string().notRequired(),
    website: Yup.string().notRequired(),
    // TODO - should we convert this to camelCase?
    lead_source: Yup.string().notRequired(),
    lead: Yup.boolean().default(true).notRequired(),
  }).notRequired(),
});

// Nested typing has issues still; might need to adjust tsconfig https://github.com/jquense/yup#typescript-setting
export type SegmentIdentifySchema = Required<Pick<Yup.InferType<typeof segmentIdentifySchema>, 'userId'>> &
{
  traits?: Partial<Exclude<UnpackRecord<Pick<Yup.InferType<typeof segmentIdentifySchema>,
   'traits'>>, 'address' | 'company'>> & {
    address?: Yup.InferType<typeof addressSchema>;
    company?: Yup.InferType<typeof companySchema>;
  };
};
