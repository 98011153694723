export const StripeCardExpiryField = {
  Container: 'stripe-card-expiry-container',
} as const;

export const StripeCardCvcField = {
  Container: 'stripe-card-cvc-container',
} as const;

export const StripeCardNumberField = {
  Container: 'stripe-card-number-container',
} as const;
