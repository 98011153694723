import React, { ReactElement } from 'react';
import { Typography } from '../../components/Typography';
import { Label, LabelProps } from './Label';
type Props = {
  control: ReactElement;
  label: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelProps?: Record<string, any>;
} & LabelProps;
export type FormControlLabelProps = Props;
export const FormControlLabel = ({
  control,
  disabled,
  label,
  labelProps,
  ...rest
}: Props) => {
  const controlProps = {
    disabled
  };
  return <Label alignItems="center" display="inline-flex" cursor={disabled ? 'default' : 'pointer'} verticalAlign="middle" {...rest} data-sentry-element="Label" data-sentry-component="FormControlLabel" data-sentry-source-file="FormControlLabel.tsx">
      {React.cloneElement(control, controlProps)}
      <Typography cursor="inherit" variant="body2" opacity={disabled ? 0.26 : undefined} style={{
      cursor: 'inherit'
    }} {...labelProps} data-sentry-element="Typography" data-sentry-source-file="FormControlLabel.tsx">
        {label}
      </Typography>
    </Label>;
};