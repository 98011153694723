import { NextRouter } from 'next/router';
import { DogWalkerAppLink, WagWebAppUrl } from '../../constants';
import { HttpStatus } from '../../constants/http-status';
import { WagApiError } from '../extractWagApiError';

export const walkerSignupErrorHandling = (err: WagApiError, router: NextRouter) => {
  if (err.response.status === HttpStatus.CONFLICT && err.response.data.type === '_walker.application_pending') {
    router.push(WagWebAppUrl.WalkerSignupFlow);
    return true;
  }

  if (err.response.status === HttpStatus.CONFLICT && err.response.data.type === '_walker.already_approved') {
    router.push(DogWalkerAppLink.AppStore);
    return true;
  }

  return false;
};
