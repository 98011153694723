import React from 'react';
import { Image as WagImage, ImageProps as WagImageProps } from '../../wag-react/next-components/image';
import { useGetResponsivePropValue } from '../../hooks/useGetResponsivePropValue';

// TODO - move this support to wag-react
export type ImageProps = Omit<WagImageProps, 'src'> & {
  src: string | string[];
};
export const Image = ({
  src,
  ...rest
}: ImageProps) => {
  const imageSrc = useGetResponsivePropValue(src);
  return <WagImage src={imageSrc} {...rest} data-sentry-element="WagImage" data-sentry-component="Image" data-sentry-source-file="index.tsx" />;
};