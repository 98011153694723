export const HeroHeader = {
  HeroCtaBtn: 'hero-cta-button',
};

export const CommentForm = {
  NameField: 'nameField',
  EmailField: 'emailField',
  CommentField: 'commentField',
  SubmitBtn: 'submitButton',
  Form: 'form',
};

export const CommentList = {
  CommentName: 'commentName',
  CommentText: 'commentText',
  CommentDate: 'commentDate',
};
