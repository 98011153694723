import React, { ReactNode } from 'react';
import { Box, BoxProps } from '../../../Box';
import { Flex } from '../../../Flex';
import { IllustrationAndText } from '../../../IllustrationAndText';
import { SectionHeading, SectionHeadingProps } from '../../../SectionHeading';
type HowItWorksBlockItem = {
  description?: string;
  descriptionBlock?: ReactNode;
  illustration: string;
  illustrationAlt?: string;
  title?: string;
};
export type HowItWorksBlockItemProp = HowItWorksBlockItem[];
export type Props = {
  items: HowItWorksBlockItemProp;
  headingText?: ReactNode;
  headingTextProps?: Omit<SectionHeadingProps, 'children'>;
} & BoxProps;
export const HowItWorksBlock = ({
  headingText = 'How it works',
  headingTextProps,
  items,
  ...rest
}: Props) => <Box {...rest} data-sentry-element="Box" data-sentry-component="HowItWorksBlock" data-sentry-source-file="index.tsx">
    <SectionHeading mx="auto" {...headingTextProps} data-sentry-element="SectionHeading" data-sentry-source-file="index.tsx">
      {headingText}
    </SectionHeading>
    <Flex alignItems={['center', 'center', 'flex-start']} justifyContent={['flex-start', 'flex-start', 'center']} flexDirection={['column', 'column', 'row']} mt={['16px', '32px', '40px']} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
      {items.map(({
      description,
      descriptionBlock,
      illustration,
      illustrationAlt,
      title
    }, index) => {
      const isFirstItem = index === 0;
      const key = `IllustrationAndText-${index}`;
      return <IllustrationAndText key={key} descriptionText={description} descriptionBlock={descriptionBlock} illustrationSrc={illustration} illustrationAlt={illustrationAlt} ml={isFirstItem ? undefined : [0, 0, '16px']} mt={isFirstItem ? undefined : ['40px', '32px', 0]} titleText={title} width={['100%', '524px', '356px']} />;
    })}
    </Flex>
  </Box>;