import * as ArticleCommentForm from './components/article-comment-form';
import * as ArticleCommentList from './components/article-comment-list';
import * as DailyWagArticle from './components/daily-wag-articles';
import * as HealthArticle from './components/health-articles';
import * as WagWellnessPlansPage from './components/wag-wellness-plans-page';
import * as StripeElements from './components/stripe-elements';

import * as DogSitterPage from './pages/dog-sitter';
import * as DogWalkerPage from './pages/dog-walker';
import * as CityServicePage from './pages/city-service';
import * as BookingFlow from './pages/booking-flow';
import * as Authentication from './pages/authentication';

export const DataTestIds = {
  ...ArticleCommentList,
  ...ArticleCommentForm,
  ...HealthArticle,
  BookingFlow,
  DailyWagArticle,
  DogSitterPage,
  DogWalkerPage,
  WagWellnessPlansPage,
  CityServicePage,
  StripeElements,
  Authentication,
} as const;
