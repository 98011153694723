export const generateSha256Hash = async (str) => {
  if (typeof window === 'undefined') {
    return '';
  }

  // https://www.fxsitecompat.dev/en-CA/docs/2020/web-crypto-api-is-no-longer-available-on-insecure-sites/
  if (!window.crypto || !window.crypto.subtle) {
    return '';
  }

  const msgUint8 = new TextEncoder().encode(str); // encode as (utf-8) Uint8Array
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};
