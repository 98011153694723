export const DogSitterHeroForm = {
  FirstName: 'first-name',
  LastName: 'last-name',
  MiddleName: 'middle-name',
  Email: 'email',
  ConfirmEmail: 'confirm-email',
  Phone: 'phone',
  TermsAndConditions: 'terms-and-conditions',
  USResident: 'us-resident',
  SubmitBtn: 'submit-btn',
  RequestError: 'request-error',
};
